<header class="header p-shadow-9">
  <p-toolbar styleClass="p-toolbar-primary p-toolbar-no-radius">
    <div class="p-toolbar-group-left">
      <a class="p-mr-4" [routerLink]="'/'">
        <svg
          width="56"
          height="24"
          viewBox="0 0 56 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.4932 0.319397H16.2539L24.0362 23.6806H31.4581L39.2404 0.319397H33.001L27.8429 17.4297H27.6514L22.4932 0.319397Z"
            fill="white"
          />
          <path
            d="M21.1845 8.78327C20.6214 3.19392 16.5106 0 10.9357 0C4.7865 0 0 4.27757 0 12C0 19.6882 4.67388 24 10.9357 24C17.2652 24 20.7678 19.6426 21.1845 15.5019L15.5533 15.4677C15.1591 17.7148 13.4923 19.0494 11.0484 19.0494C7.75976 19.0494 5.68749 16.6426 5.68749 12C5.68749 7.53992 7.71471 4.95057 11.0822 4.95057C13.6162 4.95057 15.2717 6.44487 15.5533 8.78327H21.1845Z"
            fill="#212121"
          />
          <path
            d="M37.2708 23.6806H42.8456V15.6844H45.7063L49.9184 23.6806H56L51.2135 14.7833C53.7476 13.6198 55.2117 11.327 55.2117 8.09886C55.2117 3.29658 51.9794 0.319397 46.7987 0.319397H37.2708V23.6806ZM42.8456 11.2357V4.87073H45.5936C48.0939 4.87073 49.4341 5.93156 49.4341 8.09886C49.4341 10.2662 48.0939 11.2357 45.5936 11.2357H42.8456Z"
            fill="white"
          />
        </svg>
      </a>

      <div class="header-menu" *ngIf="!enableSidebarToggle && userInfo?.userId">
        <carvector-header-menu [menu]="menu"></carvector-header-menu>
      </div>

      <button
        [ngClass]="{ button_visible: enableSidebarToggle }"
        pButton
        pRipple
        type="button"
        icon="pi pi-bars"
        class="p-button-rounded header__button"
        (click)="toggleSidenav.emit()"
      ></button>
    </div>

    <div class="p-toolbar-group-right">
      <carvector-header-user-button [userInfo]="userInfo"></carvector-header-user-button>
    </div>
  </p-toolbar>
</header>
